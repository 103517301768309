import React from 'react';
import classnames from 'classnames';

import { FileIcon, LayoutIcon, SquareIcon } from './icons.jsx';

import styles from './main-view.module.sass';
import {
  useBubblesActions,
  useBubblesState,
} from '../../store/hooks/use-bubbles';

const ViewModeControls = () => {
  const {
    focusedBubble,
  } = useBubblesState();
  const { setViewMode } = useBubblesActions();

  const { viewMode, documentBubbles, canvasBubbles } = focusedBubble;

  const hasCanvasBubbles = focusedBubble.canvasChildren.length > 0;
  const hasDocumentBubbles = focusedBubble.documentChildren.length > 0;

  //console.log(focusedBubble);

  return (
    <div className={styles.viewModes}>
      <button
        id="view-mode-canvas"
        className={classnames({
          [styles.selected]: viewMode === 'canvas',
          [styles.notEmpty]: hasCanvasBubbles,
        })}
        title="Canvas view"
        onClick={() => setViewMode('canvas')}
      >
        <SquareIcon />
      </button>
      <button
        id="view-mode-hybrid"
        title="Hybrid view"
        className={classnames({ [styles.selected]: viewMode === 'hybrid' })}
        onClick={() => setViewMode('hybrid')}
      >
        <LayoutIcon />
      </button>
      <button
        id="view-mode-document"
        title="Document view"
        className={classnames({
          [styles.selected]: viewMode === 'document'
        })}
        onClick={() => setViewMode('document')}
      >
        <FileIcon />
      </button>
    </div>
  );
};

export default ViewModeControls;
