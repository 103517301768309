import React, { useState, useEffect } from 'react';
import styles from './background-generator.module.sass';

const BackgroundGenerator = () => {
  const [circles, setCircles] = useState([]);

  const generateDesign = () => {
    const angle = Math.floor(Math.random() * 360);
    const color1 = `hsl(${Math.random() * 360}, ${Math.random() * 100}%, ${Math.random() * 100}%)`;
    const color2 = `hsl(${Math.random() * 360}, ${Math.random() * 100}%, ${Math.random() * 100}%)`;

    const newCircles = Array(Math.floor(Math.random() * 2) + 2)
      .fill()
      .map((_, i) => ({
        id: i,
        width: Math.random() * 300 + 100,
        top: `${50 + (Math.random() * 50)}%`,
        left: `${Math.random() * 50}%`,
        background: `linear-gradient(${angle}deg, ${color1}, ${color2})`
      }));    

    setCircles(newCircles);
  };

  useEffect(() => {
    generateDesign();
    const handleSpace = (e) => e.code === 'Space' && generateDesign();
    window.addEventListener('keydown', handleSpace);
    return () => window.removeEventListener('keydown', handleSpace);
  }, []);

  return (
    <div className={styles.background}>
      <div className={styles.design}>
        {circles.map(circle => (
          <div
            key={circle.id}
            className={styles.circle}
            style={{
              width: circle.width,
              height: circle.width,
              top: circle.top,
              left: circle.left,
              background: circle.background
            }}
          />
        ))}
      </div>
    </div>
  );
};


export default BackgroundGenerator;