import React from 'react';
import Header from '../header/header';
import styles from './home.module.sass';
import { useAppState } from '../../store/hooks/use-app';
import BackgroundGenerator from '../common/background-generator';
import Button from '../common/button';
import { MonitorPlay, Info, Notebook, PictureInPicture2, Sparkles } from "lucide-react";


const Home = () => {
  const { user } = useAppState();
  const [isWelcomeHidden, setIsWelcomeHidden] = React.useState(false);
  const hasBubbles = true;

  return (
    <>
      <Header />
      {/* <BackgroundGenerator /> */}
      <div className={styles.home}>
        <div className={`${styles.welcome} ${isWelcomeHidden ? styles.hidden : ''}`}>
          <div className={styles.content}>
            <h1>Tervetuloa Bubblesiin,</h1>

            <p>kuulut noin kolmenkymmenen ensimmäisen Bubblesin testikäyttäjän joukkoon - jännittävää!</p>

            <p><em>Bubblesin tarkoitus on sujuvoittaa ideoiden jäsentelyä ja työstämistä.</em></p>

            <ul>
              <li>Ensisijainen tavoitteeni on saada palautetta, eli annahan kuulua valitsemaasi kanavaa käyttäen (esim. oikeasta yläkulmasta).</li>
              <li>Kaikkea saa kokeilla, minkään ei pitäisi hajota ainakaan pahasti.</li>
              <li>Kysele matalalla kynnyksellä kaikkea - tyhmätkin kysymykset kiinnostaa, ehkä jopa erityisesti!</li>
            </ul>

            <div className={styles.buttons}>
              <Button icon={<MonitorPlay />} primary label="Katso video" />
              <Button icon={<img src="https://bubb.ls/bubbles-logomark-black.svg" />} styles={styles.buttonWithLogo} label="Jatka kotivalikkoon &rarr;" onClick={() => setIsWelcomeHidden(true)} />
            </div>

            <div className={styles.creatorProfile}>
              <img src="https://timovirtanen.fi/bubbles-creator.jpg" alt="" />
              <p>
                Propellipäisin terveisin, <br />
                <span style={{ fontWeight: '600' }}>Timo Virtanen</span> <br />
                <a href="https://x.com/tvirtanen_" target="_blank" rel="noreferrer">Twitter / X</a>, &nbsp;
                <a href="https://linkedin.com/in/timovirtanen1" target="_blank" rel="noreferrer">LinkedIn</a>
              </p>
            </div>

          </div>
        </div>

        <div className={styles.quickLinks}>
          <div className={styles.module}>
            <h3>Latest Bubbles</h3>

            {hasBubbles ? (
              <div className={styles.bubbles}>
                <div className={styles.bubble}>
                <div className={styles.bubbleHeader}>Bubble title</div>
                <div className={styles.bubbleContent}>
                  <p>Bubble content</p>
                </div>
                </div>
              </div>
            ) : (
              <div className={styles.info}>
                You'll see your latest Bubbles here.
              </div>
            )}

          </div>
          <div className={`${styles.module} ${styles.keyTopics}`}>
            <h3>Key topics</h3>

            {hasBubbles ? (
              <div className={styles.topics}>
                <div className={styles.topic}>
                <div className={styles.topicName}>Information theory</div>
              </div>
              <div className={styles.topic}>
                <div className={styles.topicName}>Design</div>
              </div>
              <div className={styles.topic}>
                <div className={styles.topicName}>#bubbles</div>
              </div>
              <div className={styles.topic}>
                <div className={styles.topicName}>Brains</div>
              </div>
              </div>
            ) : (
              <div className={styles.info}>
                You'll see your key topics here once there are at least 10 Bubbles with content.
              </div>
            )}
          </div>

          {!hasBubbles && (
            <>
              <h3>What are Bubbles anyway?</h3>
              <div className={styles.buttons}>
                <Button label="New Notepad" icon={<Notebook />} />
                <Button label="New Canvas" icon={<PictureInPicture2 />} />
              </div>
            </>
          )}


        </div>

      </div>
          {/* <div className={styles.module}>
            <h3>On this day</h3>
            <div className={styles.bubbles}>
              <div className={styles.bubble}>
                <div className={styles.bubbleHeader}>Bubble title</div>
                <div className={styles.bubbleContent}>
                  <p>Bubble content</p>
                </div>
              </div>
            </div>
          </div> */}
      {/* <div className={styles.randomBubble}> 
        <Button icon={<Sparkles />} primary label="Take me to a random Bubble" />
      </div> */}
    </>
  );
};

export default Home;