import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import styles from './document.module.sass';
import { useAppState } from '../../../store/hooks/use-app';

import {
  useBubblesActions,
  useBubblesState,
} from '../../../store/hooks/use-bubbles';

import { v4 as uuidv4 } from 'uuid';

import { useCookies } from 'react-cookie';

import {
  BlockNoteSchema,
  defaultBlockSpecs,
  filterSuggestionItems,
  insertOrUpdateBlock,
} from "@blocknote/core";
import { useCreateBlockNote, SuggestionMenuController, getDefaultReactSlashMenuItems, SideMenuController, DragHandleButton } from '@blocknote/react';
import { BlockNoteView } from '@blocknote/mantine';
import "@blocknote/mantine/style.css";
import "@blocknote/core/fonts/inter.css";
import { AIContent } from './ask-menu';
import { LuSparkles } from "react-icons/lu";
import { property } from 'lodash';


const aiSchema = BlockNoteSchema.create({
  blockSpecs: {
    // Adds all default blocks.
    ...defaultBlockSpecs,
    // Adds the Alert block.
    ai: AIContent,
    image: undefined,
    file: undefined,
    video: undefined,
    audio: undefined
  },
});

const insertAIcontent = (editor) => ({
  title: "AI Content",
  onItemClick: () => {
    insertOrUpdateBlock(editor, {
      type: "ai",
    });
  },
  aliases: [
    "chatgpt",
    "ai",
    "image",
    "chat",
    "text",
    "ask",
  ],
  group: "AI",
  icon: <LuSparkles />,
});



const buildDocument = ({ parentId, documentChildren, bubbles }) => {
  // go through document children and their children
  // parse the tree and add them as nodes accordingly
  const nodes = documentChildren.reduce((res, bubbleId) => {
    const bubble = bubbles.find((b) => b.id === bubbleId);
    if (bubble?.name?.length > 0 && JSON.parse(bubble.name).content) {
      const form = JSON.parse(bubble.name);
      for (let i = 0; i < form.content.length; i++) {
        if (typeof (form.content) != 'string' && !form.content[i].hasOwnProperty("styles")) {
          form.content[i].styles = {};
        }
      }
      res.push(form)
    }
    return res;
  }, [])
  if (nodes.length == 0) nodes.push({ id: documentChildren[0], type: 'paragraph', content: '' })
  return nodes;
};

const Document = ({
  bubble,
  parentCanvasIndex,
  canvasParentId,
  className,
  type = 'full',
  updateCount,
  dimensions,
}) => {
  const { documentChildren, id: parentBubbleId } = bubble;

  const {
    bubbles,
    focusedBubble: { viewMode },
  } = useBubblesState();
  const {
    addDocumentBubble,
    addCanvasBubble,
    updateDocumentBubble,
    moveDocumentBubble,
    removeDocumentBubbles,
    updateBubble,
    syncBubbles,
  } = useBubblesActions();

  const [initialDoc, setInitialDoc] = useState(() => {
    if (bubble.id) {
      return buildDocument({
        parentId: bubble.id,
        documentChildren,
        bubbles,
      });
    }
  });

  const [cookies, setCookie] = useCookies(['documentWidth']);
  const [documentWidth, setDocumentWidth] = useState(() => {
    return cookies.documentWidth ? parseInt(cookies.documentWidth, 10) : 320;
  });

  const documentRef = useRef(null);



  const handleResizeMouseDown = (e) => {
    const startX = e.clientX;
    const startWidth = documentWidth;

    const handleMouseMove = (e) => {
      const newWidth = e.clientX;
      if (newWidth < 320) {
        newWidth = 320;
      }
      setDocumentWidth(newWidth);
      setCookie('documentWidth', newWidth, { path: '/', maxAge: 604800 });
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };



  const cn =
    className || classnames(styles.document, styles[`${viewMode}ViewMode`]);

  useEffect(() => {
    if (viewMode === 'document') {
      setDocumentWidth('100%');
    } else if (viewMode === 'hybrid') {
      setDocumentWidth(cookies.documentWidth || 320);
    }
  }, [viewMode]);

  // document should update when bubble id changes (main doc)
  useEffect(() => {
    if (bubble.id && type === 'full') {
      onBubbleChange();
    }
  }, [bubble.id]);


  useEffect(() => {
    refreshContent()
  }, [updateCount]);

  if (updateCount == undefined) {
    useEffect(() => {
      refreshContent()
    }, [documentChildren]);
  }

  // update text content when generating from API
  useEffect(() => {
    if (bubble.fromHtml?.length > 0) {
      try {
        editor.tryParseMarkdownToBlocks(bubble.fromHtml).then((blocks)=>{
          for(let i = 0; i < documentChildren.length && i < blocks.length; i++){
            blocks[i].id = documentChildren[i];
            updateBubble({
              id: documentChildren[i],
              properties: { fromHtml: '' },
            });
          }
          editor.replaceBlocks(documentChildren, blocks);
          updateBubble({
            id: bubble.id,
            properties: { fromHtml: '' },
          });
        })
      } catch (e) {
        console.error(e);
      }
    }
  }, [bubble.fromHtml]);


  const editInit = initialDoc?.length > 0 ? {initialContent: initialDoc, trailingBlock: false, schema: aiSchema}  : {initialContent: [{id:documentChildren[0], type:'paragraph', content:''}],trailingBlock: false, scherma: aiSchema}
  const editor = useCreateBlockNote(editInit);

  useEffect(()=>{
    console.log("alma")
    if(editor.prosemirrorView == undefined){
      syncBubbles()
    }
    try{
      editor.prosemirrorView.focus()
    }catch(e){}
  
  }, [editor.prosemirrorView])

  const onBubbleChange = () => {
    const newDoc = buildDocument({
      parentId: bubble.id,
      documentChildren,
      bubbles,
    });

    

    if (editor.document.length > 0){
      const allBlocks = editor.document.reduce((r, b) => { r.push(b.id); return r;}, []);
      if(newDoc.length > 0){
        editor.replaceBlocks(allBlocks, newDoc);
      }
      else {
        editor.removeBlocks(allBlocks)
      }
    }
  }

  const refreshContent = () => {
    const newDoc = buildDocument({
      parentId: bubble.id,
      documentChildren,
      bubbles,
    });
    if (editor.document.length > 0) {
      const textCursorPosition = editor.getTextCursorPosition();
      const allBlocks = editor.document.reduce((r, b) => { r.push(b.id); return r; }, []);
      if (newDoc.length > 0) {
        editor.replaceBlocks(allBlocks, newDoc);
      }
      else {
        allBlocks.pop()
        editor.removeBlocks(allBlocks)
      }
      if(editor.prosemirrorView){
        editor.setTextCursorPosition(textCursorPosition.block, "end");
      }
    }

  }

  const onDocumentChange = () => {
    const prevContent = documentChildren;
    const existingNodes = [];
    //we never add the last bubble, as it is always empty
    for (let i = 0; i < editor.document.length; i++) {

      const doc = editor.document[i];
      existingNodes.push(doc.id)
      if (prevContent.indexOf(doc.id) >= 0) {
        const prevDoc = bubbles.find((b) => b.id === doc.id);

        const formattedName = JSON.stringify({ id: doc.id, type: doc.type, content: doc.content, props: doc.props, children: doc.children })
        if (prevDoc?.name != formattedName) {
          updateDocumentBubble({
            id: doc.id,
            canvasParentId,
            parentCanvasIndex,
            properties: { name: formattedName },
          });
        }
      }
      else {
        addDocumentBubble({
          id: doc.id,
          parentBubbleId: bubble.id,
          previousSibling: i > 0 ? editor.document[i - 1].id : "",
          properties: { name: JSON.stringify({ id: doc.id, type: doc.type, content: doc.content, props: doc.props, children: doc.children }) },
          canvasParentId,
          parentCanvasIndex,
        });
      }
    }

    for (let i = 0; i < editor.document.length - 1; i++) {
      const oldIndex = prevContent.indexOf(editor.document[i].id);
      if (oldIndex != -1 && oldIndex != i) {
        const doc = editor.document[i];
        moveDocumentBubble({
          id: doc.id,
          newIndex: i,
          oldIndex,
          canvasParentId,
          parentCanvasIndex,
          parentBubbleId: bubble.id,
        });
      }
    }


    const ghostNodes = documentChildren.filter(
      (id) => !existingNodes.includes(id),
    );

    if (ghostNodes?.length) {
      removeDocumentBubbles({
        parentBubbleId,
        ids: ghostNodes,
      });
    }



  };

  let initialBlock = undefined;

  const dragStart = () => {
    console.log(editor.prosemirrorView.dom.getElementsByClassName("ProseMirror-selectednode"))
    initialBlock = editor.prosemirrorView.dom.getElementsByClassName("ProseMirror-selectednode")[0];
  }

  const onDrag = async (e) => {
    const baseBlock = editor.prosemirrorView.dom.getElementsByClassName("ProseMirror-selectednode")[0];
    if (baseBlock == undefined) {
      if (initialBlock != undefined) {
        baseBlock = initialBlock;
      }
      else {
        return;
      }
    }

    let block = undefined;
    let listPar = undefined;
    if (baseBlock.querySelector(".bn-block-content").getAttribute("data-content-type") == "bulletListItem") {
      const recListSearch = (obj, sID) => {
        let res = undefined;
        if (typeof obj === 'object' && obj !== null) {
          if (obj['id'] == sID) {
            res = obj;
            obj = {};
          }
          if (res == undefined)
            for (let c of obj.children) {
              res = recListSearch(c, sID)[0]
              if (res != undefined){
                obj.children.splice(obj.children.indexOf(c), 1)
                break;
              }
            }
        }
        return [res, obj];
      }
      for (let b of documentChildren) {
        let bubb = bubbles.find((f) => { return f.id == b })
        if (bubb && bubb?.name?.length > 0) {
          let toTest = JSON.parse(bubb.name);
          if (toTest.type != "bulletListItem") {
            continue;
          }
          const res = recListSearch(toTest, baseBlock.getAttribute("data-id"))
          block = res[0]
          if (block != undefined) {
            //console.log(res)
            //bubb.name = JSON.stringify(res[1]);
            listPar = toTest.id;
            break;
          }
        }
      }

    }
    else {
      block = JSON.parse(bubbles.find((b) => { return b.id == baseBlock.getAttribute("data-id") }).name);
    }


    //block[0].id = baseBlock.getAttribute("data-id");
    const canvasID = uuidv4()



    if (document.querySelectorAll("#document:hover").length > 0) {
      const hoveredBubble = bubbles.find((b) => { return b.id == document.querySelector("#document:hover").getAttribute("bubble-id") }).documentChildren;
      if (hoveredBubble.indexOf(baseBlock.getAttribute("data-id")) >= 0 || (listPar != undefined && hoveredBubble.indexOf(listPar) >= 0)) {
        return;
      }
      addDocumentBubble({
        id: block.id,
        parentBubbleId: document.querySelector("#document:hover").getAttribute("bubble-id"),
        properties: {
          name: JSON.stringify(block),
        }
      })
      editor.removeBlocks([baseBlock.getAttribute("data-id")]);
    }
    else {
      const bounds = document.getElementById("canvas").getBoundingClientRect()
      addCanvasBubble({
        id: canvasID,
        location: {
          x: e.clientX - bounds.left,
          y: e.clientY - bounds.top
        }
      })
      addDocumentBubble({
        id: block.id,
        parentBubbleId: canvasID,
        properties: {
          name: JSON.stringify(block),
        }
      })
      editor.removeBlocks([baseBlock.getAttribute("data-id")]);
      initialBlock = undefined
    }
  }

  const captureCopyPaste = (e) => {
    e.stopPropagation();
  };

  const dynamicStyles = {
    minWidth: 'inherit',
    maxWidth: viewMode === 'document' ? '100%' : documentWidth,
  };

  return (
    <div
      className={cn}
      id="document"
      onKeyDown={captureCopyPaste}
      bubble-id={bubble.id}
      style={type === 'full' ? dynamicStyles : {}}
    >
      {bubble.imageContent && <img src={bubble.imageContent} />}
      <div className={`textContent ${styles.textContent}`}>
        {initialDoc ? (
          <BlockNoteView editor={editor} onChange={onDocumentChange} theme={"light"} onDragEnd={onDrag} onDragStart={dragStart} slashMenu={false} sideMenu={true}>

            <SuggestionMenuController
              triggerCharacter={"/"}
              getItems={async (query) =>
                // Gets all default slash menu items and `insertAlert` item.
                filterSuggestionItems(
                  [...getDefaultReactSlashMenuItems(editor), insertAIcontent(editor)],
                  query
                )
              }
            />
          </BlockNoteView>
        ) : null}
      </div>

      {type === 'full' ? (
        <div
          className={styles.resizeHandle}
          onMouseDown={handleResizeMouseDown}
        />
      ) : null}
    </div>
  );
};

export const InlineDocument = ({
  bubble,
  parentCanvasIndex,
  className,
  updateCount,
  canvasParentId,
  dimensions,
}) => (
  <Document
    dimensions={dimensions}
    updateCount={updateCount}
    bubble={bubble}
    parentCanvasIndex={parentCanvasIndex}
    canvasParentId={canvasParentId}
    className={className}
    type="inline"
  />
);

export default Document;
