    export function getBubbleName(bubble) {
        let res = "";
    
        // Helper function to recursively traverse the object
        function traverse(item) {
            if (typeof item === 'object' && item !== null) {
                // If the item is an object or array, iterate through its keys or elements
                for (let key in item) {
                    if (key === 'text') {
                        // If the key is 'text', push its value to textValues
                        res += item[key];
                    }
                    // Recursively traverse the value
                    traverse(item[key]);
                }
            }
        }
    
        traverse(JSON.parse(bubble?.name));
        return res;
    }