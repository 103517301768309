import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { Route, Routes } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import App from './App.jsx';
import store, { history } from './store/store';
import Invite from './components/invite/invite';
import Subscription from './components/subscription/subscription';
import Home from './components/home/home';
const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

/**
 * Workaround for React removeChild issues
 *
 **/
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error(
          'Cannot remove a child from a different parent',
          child,
          this,
        );
      }
      return child;
    }
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error(
          'Cannot insert before a reference node from a different parent',
          referenceNode,
          this,
        );
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments);
  };
}
/**
 * End of workaround
 *
 **/

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <Routes>  
          <Route path="/:bubbleId" element={<App />} />
          <Route path="/invite" element={<Invite />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/home" element={<Home />} />
          <Route path="/*" element={<App />} />
        </Routes>
      </Router>
    </PersistGate>
  </Provider>,
);
