import React, { useEffect, useState } from 'react';

import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import styles from './main-view.module.sass';

import { useSelector } from 'react-redux';
import { useAppState } from '../../store/hooks/use-app';
import {
  useBubblesActions,
  useBubblesState,
} from '../../store/hooks/use-bubbles';
import Canvas from './canvas/canvas';
import Document from './document/document';
import Title from './title';
import Button from '../common/button';
import useClipboard from './use-clipboard';
import ViewModeControls from './view-mode-controls';

const getBubbleIdFromPath = (path) =>
  path === '/' || path === '' ? undefined : path.split('/')[1];

const MainView = () => {
  const { handleCopyPaste } = useClipboard();
  const { loggedIn } = useAppState();
  const { focusedBubble, loading, focusedBubbleId } = useBubblesState();
  const { loadBubbles, syncBubbles, syncToUrl, newCanvas } = useBubblesActions();
  const [isClosed, setIsClosed] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const pathname = useSelector((state) => state.router.location.pathname);

  const handleClose = () => {
    setIsClosed(true);
  };

  const handleNew = () =>{
    newCanvas();
    setIsClosed(false);
  }

  const handleMinimize = () => {
    setIsMinimized(true);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 2,
      },
    }),
  );

  useEffect(() => {
    if (loggedIn) {
      loadBubbles({
        newFocusedBubbleId: getBubbleIdFromPath(pathname),
        isInitialLoad: true,
      });
    }
  }, []);

  useEffect(() =>{
    setIsClosed(false);
  }, [focusedBubble])

  // sync focused bubble to path (when going back/forward in history)
  useEffect(() => {
    const routerId = getBubbleIdFromPath(pathname);
    if (routerId && routerId !== focusedBubbleId && !loading) {
      syncToUrl({ idInPath: routerId });
    }
  }, [pathname]);

  if (!focusedBubble) {
    return null;
  }

  return (
    <div
      className={`${styles.mainView} viewMode-${focusedBubble.viewMode}`}
      onKeyDown={handleCopyPaste}
    >
      <DndContext sensors={sensors}>
        <div className={styles.guide} onDoubleClick={handleNew}>
          <div className={styles.guideContent}>
            <p className={styles.noTextHighlight}>+ Double-click to create a new bubble</p>
          </div>
        </div>
        <BubbleDocumentView focusedBubble={focusedBubble} handleClose={handleClose} isClosed={isClosed} isMinimized={isMinimized}/>
      </DndContext>
    </div>
  );
};

const BubbleDocumentView = (props) => {
  return(<div
    className={`${styles.wrapper} ${props.isClosed ? styles.closed : ''} ${props.isMinimized ? styles.minimized : ''}`}
  >
    <ViewModeControls />
    <Title bubble={props.focusedBubble} />
    <div className={styles.bubbleControls}>
      {/* <div className={styles.stepOut} onClick={handleMinimize}>
        Step out
      </div> */}
      <Button label="Close" size="small" onClick={props.handleClose} />
    </div>
    <div className={`${styles.contentWrapper}`}>
      <Document bubble={props.focusedBubble} />
      <Canvas focusedBubble={props.focusedBubble} />
    </div>
  </div>);
 }

export default MainView;
