import React from 'react';
import { defaultProps } from "@blocknote/core";
import { createReactBlockSpec } from "@blocknote/react";
import { Menu, ActionIcon } from "@mantine/core";
import { LuSparkles, LuScrollText, LuImage } from "react-icons/lu";
import styles from './ask-menu.module.sass'
import { useBubblesActions } from '../../../store/hooks/use-bubbles';
import { getBubbleName } from './document-func';

// The types of AI content that users can choose from.
export const contentTypes = [
  {
    title: "Text generation",
    value: "text",
    icon: LuScrollText,
    color: "#e69819",
    backgroundColor: {
      light: "#fff6e6",
      dark: "#805d20",
    },
  },
  {
    title: "Image generation",
    value: "image",
    icon: LuImage,
    color: "#d80d0d",
    backgroundColor: {
      light: "#ffe6e6",
      dark: "#802020",
    },
  },
];

 
export const AIContent = createReactBlockSpec(
  {
    type: "ai",
    propSchema: {
      textAlignment: defaultProps.textAlignment,
      textColor: defaultProps.textColor,
      type: {
        default: "text",
        values: ["text", "image"],
      },
    },
    content: "inline",
  },
  {
    render: (props) => {
      const { handleImageGeneration, handleTextGeneration } = useBubblesActions();

      const contentType = contentTypes.find(
        (a) => a.value === props.block.props.type
      );
      const onGenerateInit = () =>{
        if(props.block.props.type == "text"){
            handleTextGeneration({promptText: getBubbleName({name:JSON.stringify(props.block.content)})})
        }
        else{
            handleImageGeneration({promptText: getBubbleName({name:JSON.stringify(props.block.content)})})
        }
      };
      const Icon = contentType.icon;
      return (
        <div className={styles.aiContent}>
          {/*Icon which opens a menu to choose the AI content type*/}
          <Menu withinPortal={false} zIndex={999999}>
            <Menu.Target>
              <div className={styles.aiContentIconWrapper} contentEditable={false}>
              <Icon
                  className={styles.aiContentIcon}
                  data-content-icon-type={props.block.props.type}
                  size={32}
                />
              </div>
            </Menu.Target>
            {/*Dropdown to change the AI content type*/}
            <Menu.Dropdown>
              <Menu.Label>Generation types</Menu.Label>
              <Menu.Divider />
              {contentTypes.map((type) => {
                const ItemIcon = type.icon;
 
                return (
                  <Menu.Item
                    key={type.value}
                    leftSection={
                      <ItemIcon
                        className={styles.aiContentIcon}
                        data-content-icon-type={type.value}
                      />
                    }
                    onClick={() =>
                      props.editor.updateBlock(props.block, {
                        type: "ai",
                        props: { type: type.value },
                      })
                    }>
                    {type.title}
                  </Menu.Item>
                );
              })}
            </Menu.Dropdown>
          </Menu>
          {/*Rich text field for user to type in*/}
          <div className={styles.inlineContent} ref={props.contentRef} />
          <div className={styles.runButton} onClick={onGenerateInit}>
            <LuSparkles size={16} color='black' />
            <div className={styles.label}>Run</div>
          </div>
        </div>
      );
    },
  }
);
 