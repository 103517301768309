import React, { useEffect, useState } from 'react';

import styles from './name-editor.module.sass';

import {
  BlockNoteSchema,
  defaultBlockSpecs,q0
} from "@blocknote/core";
import { useCreateBlockNote } from '@blocknote/react';
import { BlockNoteView } from '@blocknote/mantine';
import "@blocknote/mantine/style.css";
import "@blocknote/core/fonts/inter.css";

const nameSchema = BlockNoteSchema.create({
  blockSpecs: {
    // Adds all default blocks.
    ...defaultBlockSpecs,
    // Adds the Alert block.
    image: undefined,
    file: undefined,
    video: undefined,
    audio: undefined
  },
});

export function NameEditor({ bubbleId, initialName, onNameChange, updateCount }) {

  const editor = useCreateBlockNote({schema: nameSchema, trailingBlock: false})
  const [prevIN, setPrevIN] = useState("");
  const onChange = () => {
    if (editor.document.length > 1) {
      let res = editor.document.reduce((toRem, b) => { toRem.push(b.id); return toRem; }, []);
      res.shift()
      editor.removeBlocks(res);
    }
    setPrevIN(JSON.stringify(editor.document[0]))
    onNameChange(JSON.stringify(editor.document[0]))
  }

  useEffect(() => {
    try {
      if (editor.document.length > 0 && prevIN != initialName) {
        let res = editor.document.reduce((toRem, b) => { toRem.push(b.id); return toRem; }, []);
        if(initialName.length > 0){
          editor.replaceBlocks(res, [JSON.parse(initialName)])
        }
        else{
          editor.replaceBlocks(res, [{type: "paragraph", content: "",}])
        }
        
      }
    } catch (ex) { }

  }, [updateCount])

  useEffect(() => {
    try {
      if (editor.document.length > 0) {
        let res = editor.document.reduce((toRem, b) => { toRem.push(b.id); return toRem; }, []);
        if(initialName.length > 0){
          editor.replaceBlocks(res, [JSON.parse(initialName)])
        }
        else{
          editor.replaceBlocks(res, [{type: "paragraph", content: "",}])
        }
      }
    } catch (ex) { }

  }, [bubbleId])

  return (
    <div className={`${styles.editor} name-editor`}>
      <BlockNoteView
        editor={editor}
        theme={'light'}
        sideMenu={false}
        formattingToolbar={false}
        linkToolbar={false}
        filePanel={false}
        slashMenu={false}
        tableHandles={false}
        onChange={onChange}
      ></BlockNoteView>
    </div>
  );
}

export default NameEditor;
