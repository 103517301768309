import React from 'react';
import Header from '../header/header';
import styles from './invite.module.sass';
import { Copy } from 'lucide-react';
import { useAppState } from '../../store/hooks/use-app';

const Invite = () => {
  const { user } = useAppState();
  return (
    <>
      <Header />
      <div className={styles.invite}>
        <h1>Get up to 12 months free by inviting your friends to Bubbles!</h1>
        <p>For every friend who joins we'll give both of you 1 month free (up to 12 months). If want more features, <a href="https://app.bubb.ls/subscription">upgrade your account.</a></p>
        <div className={styles.inviteBox}>
            <h2>Invite your friends by sending them this link</h2>
            <input type="text" value={`https://app.bubb.ls/invite/${user.id}`} />
            <button>
                <Copy />
                Copy
            </button>

        </div>
      </div>
    </>
  );
};

export default Invite;
