import { useSelector, useDispatch } from 'react-redux';

import { loadUserData, actions } from '../app.slice';

export const useAppState = () => {
  const appState = useSelector((state) => state.app);

  return appState;
};

export const useAppActions = () => {
  const dispatch = useDispatch();

  return {
    loadUserData: () => dispatch(loadUserData()),
    setDarkMode: (darkMode) => dispatch(actions.setDarkMode({ darkMode })),
  };
};

export default {
  useAppState,
  useAppActions,
};
