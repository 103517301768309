import React from 'react';
import styles from './button.module.sass';

const Button = ({ label, price, icon, onClick, styles: customStyles, primary, size }) => {
  return (
    <button
      className={`button ${customStyles || ''} ${primary ? 'primary' : ''} ${size ? `size-${size}` : ''}`}
      onClick={onClick}
    >
      {icon && <div className="buttonIcon">{icon}</div>}
      <div className="content">
        {label && <div className="label">{label}</div>}
        {price && <div className="price">{price}</div>}
      </div>
    </button>
  );
};

export default Button;